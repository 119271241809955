import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 14",
  week: "Semana 2",
  month: "Mar",
  day: "27",
  monthNumber: "03",
  date: "2020-03-27",
  path: "/cronologia/semana-02#dia-23-mar",
};

const Day14 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 7.871 nuevos positivos y 769 personas fallecidas.
          Permanecen hospitalizadas 36.293 personas, 4.381 más que el día
          anterior, y 2.165 reciben el alta. El número de casos ingresados en la
          UCI asciende a 4.165, lo que supone un aumento de 486 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 7,6 % y la tasa de recuperados del 13,3 %.
        </ModText>

        <ModTwoCols
          src="/images/svg/27_mar_consejo_ministros.svg"
          alt="ministro trabajando en despacho"
          small={true}
        >
          El <strong>Consejo de Ministros</strong> ha aprobado una serie de{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/270320-enlace-laboral.aspx">
            medidas urgentes en el ámbito laboral{" "}
          </InlineLink>
          para paliar los efectos derivados de la COVID-19. Entre las medidas
          contempladas, se recoge el impedir los despidos justificados por
          causas relacionadas con coronavirus y mantener los contratos
          temporales hasta que concluya la crisis.
        </ModTwoCols>
        <ModText>
          España ha solicitado a la Comisión Europea la ampliación del plazo de
          pago de las{" "}
          <strong>ayudas directas de la Política Agraria Común</strong> (PAC),
          de manera excepcional y sin ningún tipo de penalizaciones, hasta el 15
          de octubre de 2020 para asegurar que ningún beneficiario se quede sin
          cobrar sus ayudas.
        </ModText>
        <ModTwoCols
          src="/images/svg/27_mar_policia.svg"
          alt="policía revisando vehículo"
          small={true}
        >
          <strong>Policía Nacional y Guardia Civil</strong>, con el apoyo de las
          policías autonómicas y locales, intensifican su presencia y aumentan
          los puntos de control situados en los accesos de entrada y salida de
          las ciudades para{" "}
          <strong>prevenir los desplazamientos indebidos</strong> que vulneren
          las limitaciones a la movilidad impuestas con el estado de alarma
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/27_mar_justicia.svg"
          alt="balanza de la justicia"
          small={false}
          inverted={true}
        >
          La <strong>Comisión de Coordinación de Crisis de Justicia</strong>{" "}
          establece nuevas medidas para la gestión de la administración de
          Justicia durante la contención de la pandemia. Entre las medidas
          adoptadas, ha acordado activar el protocolo para inscribir en el
          Registro Civil las defunciones y otorgar las licencias de
          enterramiento debido al incremento en la mortalidad generado por la
          epidemia.
        </ModTwoCols>
        <ModCovidHighlight>
          A escala mundial, podemos decir que{" "}
          <srtong>el coronavirus confina</srtong> a un tercio de la humanidad.
        </ModCovidHighlight>
        <ModReferenceList title="Guías y Documentos Publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/270320-enlace-laboral.aspx"
            name="Medidas urgentes en el ámbito laboral"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day14;
